import React, {useState} from 'react';
import './App.css';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51OvsgsGqUywqwnjIACQj1MgB0X7wUT33sMwhV2RcBZyWSY5kWJzGjJZWCTwsvT2mRhFenQWu4abSSJaqoGE3vC0J00CWqEPw1J');

function App() {
    const [itemPrice, setItemPrice] = useState(0)
    const [paymentPlatform, setPaymentPlatform] = useState("")
    const [paymentSecret, setPaymentSecret] = useState("")
    const [showPayResult, setShowPayResult] = useState(false)

    let paymentElement = <div/>
    if (itemPrice > 0 && paymentPlatform === "stripe") {
        (async () => {
            if (paymentSecret === "") {
                const response = await fetch('/api/stripe/secret?price=' + itemPrice);
                const {client_secret: clientSecret} = await response.json();
                console.log("new client_secret", clientSecret)
                setPaymentSecret(clientSecret)
            } else {
                // Render the form using the clientSecret
                const options = {
                    // passing the client secret obtained in step 3
                    clientSecret: paymentSecret,
                    // Fully customizable with appearance API.
                    appearance: {/*...*/},
                };
                paymentElement = (<Elements stripe={stripePromise} options={options}>
                    <CheckoutForm/>
                </Elements>)
            }
        })();
    }

    let paymentResult = <div/>
    if (showPayResult) {
        
    }

    return (
        <div className="steplist">
            <div className="stepsection">
                <p>Choose Price</p>
                <input type="radio" name="price" id="eleven" value={1099} onChange={(e) => {
                    setItemPrice(parseInt(e.target.value))
                }}>
                </input><label htmlFor="eleven">$10.99</label>

                <input type="radio" name="price" id="ten" value={999} onChange={(e) => {
                    setItemPrice(parseInt(e.target.value))
                }}>
                </input><label htmlFor="ten">$9.99</label>
                <input type="radio" name="price" id="one" value={99} onChange={(e) => {
                    setItemPrice(parseInt(e.target.value))
                }}>
                </input><label htmlFor="one">$0.99</label>
            </div>
            <div className="stepsection">
                <p>Choose platform for {itemPrice}</p>
                <input type="radio" name="platform" id="stripe" value="stripe" onChange={(e) => {
                    setPaymentPlatform(e.target.value)
                }}>
                </input><label htmlFor="stripe">Stripe</label>
            </div>
            <div className="stepsection">
                <p>Pay</p>
                {paymentElement}
            </div>
            <div className="stepsection">
                <p>Result</p>
                {paymentResult}
            </div>

        </div>
    );
}

export default App;
